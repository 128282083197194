import React from 'react';
import { Link, useIntl } from 'gatsby-plugin-intl';

const Nav = () => {
  const intl = useIntl();
  return (
    <>
      {[
        { to: '/', content: 'welcome-title' },
        { to: '/#lodge', content: 'lodge-title' },
        { to: '/#region', content: 'region-title' },
        { to: '/#gallery', content: 'gallery-title' },
        { to: '/#contact', content: 'contact-title' },
      ].map((link, i) => (
        <Link
          key={i}
          to={link.to}
          className="block text-2xl md:inline-block mt-4 md:mt-0 md:ml-10 no-underline text-white uppercase"
        >
          {intl.formatMessage({ id: link.content })}
        </Link>
      ))}
    </>
  );
};

export default Nav;
