import React, { useState } from 'react';
import { Link } from 'gatsby-plugin-intl';
import posed from 'react-pose';
import Nav from 'components/layout/header/nav/nav';
import LocaleSelector from 'components/localeSelector/LocaleSelector';

const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

const Header = () => {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <AnimatedContainer>
      <header className="bg-gray-900">
        <div className="text-center max-w-3/5 p-4 md:p-8">
          <div className="flex md:block justify-between md:pb-5">
            <Link className="no-underline text-white" to="/">
              <h1 className="font-bold text-5xl tracking-tight">
                LeBasBuisson
              </h1>
            </Link>
            <button
              className="block md:hidden border-none border-white flex items-center px-3 py-2 rounded text-white bg-black"
              onClick={() => toggleExpansion(!isExpanded)}
            >
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <nav className={`${isExpanded ? 'block' : 'hidden'} md:block`}>
            <Nav />
            <LocaleSelector />
          </nav>
        </div>
      </header>
    </AnimatedContainer>
  );
};

export default Header;
