import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

const Input = ({ value, setter, placeholder, style }) => {
  const intl = useIntl();
  return (
    <input
      className={'bg-grey-lighter text-grey-darker border-none ' + style}
      placeholder={intl.formatMessage({ id: placeholder })}
      content={value}
      onChange={e => setter(e.target.value)}
    />
  );
};

export default Input;
