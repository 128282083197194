import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { Location } from '@reach/router';
import schemaGenerator from 'helpers/schemaGenerator';
import favicon from '../../images/favicon.ico';

const Head = ({
  siteTitle,
  siteDescription,
  siteUrl,
  pageTitle,
  pageTitleFull = pageTitle ? `${siteTitle} | ${pageTitle}` : siteTitle,
  themeColor,
  location,
  description,
  canonical = siteUrl + (location.pathname || ''),
}) => {
  return (
    <Helmet>
      <html lang="fr" />
      <link href={favicon} rel="icon" type="image/x-icon" />
      <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
      <meta
        content="width=device-width,initial-scale=1.0,user-scalable=yes"
        name="viewport"
      />

      <meta content={siteTitle} name="apple-mobile-web-app-title" />
      <title>{pageTitleFull}</title>

      <meta
        content={description ? description : siteDescription}
        name="description"
      />

      <meta content="yes" name="apple-mobile-web-app-capable" />
      <meta
        content="black-translucent"
        name="apple-mobile-web-app-status-bar-style"
      />
      <meta content={themeColor} name="theme-color" />
      <meta content={siteTitle} name="application-name" />

      <link rel="canonical" href={canonical} />

      <script type="application/ld+json">
        {JSON.stringify(
          schemaGenerator({
            location,
            canonical,
            siteUrl,
            pageTitle,
            siteTitle,
            pageTitleFull,
          })
        )}
      </script>
    </Helmet>
  );
};

Head.propTypes = {
  siteTitle: PropTypes.string,
  siteTitleShort: PropTypes.string,
  siteDescription: PropTypes.string,
  siteUrl: PropTypes.string,
  themeColor: PropTypes.string,
  imageUrl: PropTypes.string,
  canonical: PropTypes.string,
  pageTitle: PropTypes.string,
  pageTitleFull: PropTypes.string,
  location: PropTypes.object.isRequired,
};

const HeadWithQuery = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              siteTitle
              siteTitleShort
              siteDescription
              siteUrl
              themeColor
            }
          }
        }
      `}
      render={data => (
        <Location>
          {({ location }) => (
            <Head
              {...data.site.siteMetadata}
              {...props}
              location={location}
              description={props.description}
            />
          )}
        </Location>
      )}
    />
  );
};

export default HeadWithQuery;
