import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Section from 'components/sections/section';
import Title from 'components/title/title';
import { useIntl } from 'gatsby-plugin-intl';
import Input from 'components/Input';

const Footer = () => {
  const {
    footerJson: { networks },
  } = useStaticQuery(graphql`
    query FooterQuery {
      footerJson {
        networks {
          title
          link
          image {
            childImageSharp {
              fixed(width: 50, height: 50) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const intl = useIntl();

  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [sendStatus, setSendStatus] = useState(undefined);
  const responsive = 'w-4/5 lg:w-8/12';

  function sendEmail(e) {
    e.preventDefault();
    setSendStatus(undefined);
    setLoading(true);
    fetch(
      `https://europe-west1-lebasbuisson.cloudfunctions.net/Send-email?subject=${subject}&email=${email}&message=${message}`
    )
      .then(result => {
        if (result.status !== 200) {
          setSendStatus(intl.formatMessage({ id: 'form-error' }));
        } else {
          setSendStatus(intl.formatMessage({ id: 'form-success' }));
        }
      })
      .catch(() => {
        setSendStatus(intl.formatMessage({ id: 'form-error' }));
      })
      .finally(() => setLoading(false));
  }

  return (
    <div className="bg-gray-200 text-center">
      <Section>
        <Title as="h2" size="large" id={'contact'}>
          {intl.formatMessage({ id: 'contact-title' })}
        </Title>
        <form className="w-full lg:w-3/4 xl:w-1/2 mr-auto ml-auto">
          <div className="block md:flex my-20 text-gray-600 italic">
            <p className="flex-1 p-2 md:p-0 md:ml-4 lg:ml-0">
              pverlingue@wanadoo.fr
            </p>
            <p className="hidden md:block md:flex-1">/</p>
            <p className="flex-1 p-2 md:p-0">
              62340 Bonningues Les Calais, France
            </p>
            <p className="hidden md:block md:flex-1">/</p>
            <p className="flex-1 p-2 md:p-0 md:mr-4 lg:mr-0">
              {' '}
              Tél: 06.07.27.06.19 - 06.86.17.14.41
            </p>
          </div>
          <div className="block md:flex my-20 uppercase font-bold">
            {networks.map(network => (
              <a
                key={network.title}
                target="_blank"
                rel="noopener noreferrer"
                href={network.link}
                className="flex-1 p-2 md:p-0 no-underline text-black"
              >
                <Img
                  fixed={network.image.childImageSharp.fixed}
                  alt={network.title}
                />
              </a>
            ))}
          </div>
          <Input
            value={email}
            setter={setEmail}
            placeholder={'form-email'}
            style={'p-4 m-3 ' + responsive}
          />
          <Input
            value={subject}
            setter={setSubject}
            placeholder={'form-subject'}
            style={'p-4 m-3 ' + responsive}
          />
          <textarea
            value={message}
            onChange={e => setMessage(e.target.value)}
            placeholder={intl.formatMessage({ id: 'form-message' })}
            className={'border-none p-4 m-3 h-64 ' + responsive}
          />
          <button
            className={
              'bg-gray-900 text-white hover:bg-gray-800 font-semibold py-6 px-4 m-3 mr-auto ml-auto cursor-pointer border-none ' +
              responsive
            }
            onClick={e => sendEmail(e)}
          >
            {sendStatus
              ? sendStatus
              : loading
              ? intl.formatMessage({ id: 'form-sending' })
              : intl.formatMessage({ id: 'form-send' })}
          </button>
        </form>
      </Section>
    </div>
  );
};

export default Footer;
