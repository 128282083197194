import React from 'react';
import PropTypes from 'prop-types';
import Head from 'components/head/head';
import Header from 'components/layout/header/header';
import GlobalStyle from 'global.css.js';
import 'css/global.css';
import Map from 'components/Map/Map';
import Footer from 'components/layout/footer/footer';

const Layout = ({ children, pageTitle, description }) => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require
    require('smooth-scroll')('a[href*="#"]');
  }

  return (
    <div className="global-font">
      <GlobalStyle />
      <Head pageTitle={pageTitle} description={description} />
      <Header />
      {children}
      <Footer />
      <Map />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string,
};

export default Layout;
