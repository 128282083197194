import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl';
import Img from 'gatsby-image';

const LocaleSelector = () => {
  const data = useStaticQuery(graphql`
    query LocalesQuery {
      localesJson {
        locales {
          lang
          image {
            childImageSharp {
              fixed(width: 51, height: 30) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  `);
  const locales = data.localesJson.locales;
  const langPictures = locales.map(({ lang, image }) => {
    return { lang, fixed: image.childImageSharp.fixed };
  });

  const handleClick = (e, currentLocale) => {
    e.preventDefault();
    let index = locales.findIndex(({ lang }) => lang === currentLocale);
    if (index + 1 >= locales.length) {
      index = 0;
    } else {
      index += 1;
    }
    changeLocale(locales[index].lang);
  };

  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) => (
        <button
          className="border-none bg-transparent p-0 localeSelector md:float-right"
          onClick={e => handleClick(e, currentLocale)}
        >
          <Img
            fixed={
              langPictures.find(({ lang }) => lang === currentLocale).fixed
            }
            Tag="span"
            className="mt-4 md:mt-0"
          />
        </button>
      )}
    </IntlContextConsumer>
  );
};

export default LocaleSelector;
