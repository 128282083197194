import React from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from 'components/Map/Marker';

const Map = () => {
  return (
    <div className="w-full" style={{ height: 600 }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyCg5ho4aFyfWlGkr3VJYuSdhi_jmyWPMa0' }}
        defaultCenter={{
          lat: 50.889606,
          lng: 1.755564,
        }}
        defaultZoom={13}
      >
        <Marker lat={50.8895} lng={1.755} text={'Kreyser Avrora'} />
      </GoogleMapReact>
    </div>
  );
};

export default Map;
